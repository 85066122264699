<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :customClass="'lead-update'"
    v-if="getPermission('lead:update')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">Update {{ leadCreate.title }}</h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate"
        color="cyan"
      >
        Save Lead
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="leadForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate"
      >
        <v-container fluid>
          <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll custom-box-top-inner-shadow"
            style="max-height: 70vh; position: relative"
          >
            <v-row>
              <v-col md="6">
                <v-layout class="my-4">
                  <v-flex md4>
                    <label
                      for="lead_title"
                      class="font-weight-700 font-size-16 required"
                      >Lead Title</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <v-text-field
                      id="lead_title"
                      v-model.trim="leadCreate.title"
                      dense
                      filled
                      label="Title"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                      :rules="[
                        validateRules.required(leadCreate.title, 'Lead Title'),
                        validateRules.minLength(
                          leadCreate.title,
                          'Lead Title',
                          1
                        ),
                        validateRules.maxLength(
                          leadCreate.title,
                          'Lead Title',
                          100
                        ),
                      ]"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout class="my-4">
                  <v-flex md4>
                    <label
                      for="lead_title"
                      class="font-weight-700 font-size-16 required"
                      >First Name</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <v-text-field
                      dense
                      color="cyan"
                      filled
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="leadCreate.first_name"
                      label="First Name"
                      :rules="[
                        validateRules.required(
                          leadCreate.first_name,
                          'First Name'
                        ),
                        validateRules.minLength(
                          leadCreate.first_name,
                          'First Name',
                          1
                        ),
                        validateRules.maxLength(
                          leadCreate.first_name,
                          'First Name',
                          100
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout class="my-4">
                  <v-flex md4>
                    <label for="lead_title" class="font-weight-700 font-size-16"
                      >Last Name</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <v-text-field
                      dense
                      color="cyan"
                      filled
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="leadCreate.last_name"
                      label="Last Name"
                      :rules="[
                        validateRules.minLength(
                          leadCreate.last_name,
                          'Last Name',
                          1
                        ),
                        validateRules.maxLength(
                          leadCreate.last_name,
                          'Last Name',
                          100
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout class="my-4">
                  <v-flex md4>
                    <label for="lead_title" class="font-weight-700 font-size-16"
                      >Customer Company</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <v-text-field
                      v-model.trim="leadCreate.company"
                      dense
                      filled
                      label="Company"
                      :rules="[
                        validateRules.minLength(
                          leadCreate.company,
                          'Company',
                          1
                        ),
                        validateRules.maxLength(
                          leadCreate.company,
                          'Company',
                          100
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout class="my-4">
                  <v-flex md4>
                    <label for="lead_title" class="font-weight-700 font-size-16"
                      >Customer Email</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <v-text-field
                      v-model.trim="leadCreate.email"
                      dense
                      filled
                      label="Email"
                      :rules="[
                        validateRules.validEmail(leadCreate.email, 'Email'),
                        validateRules.minLength(leadCreate.email, 'Email', 1),
                        validateRules.maxLength(leadCreate.email, 'Email', 100),
                      ]"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout class="my-4">
                  <v-flex md4>
                    <label
                      for="phone_number"
                      class="font-weight-700 font-size-16 required"
                      >Customer Phone No.</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <PhoneTextField
                      id="phone_number"
                      v-model="leadCreate.phone_number"
                      :value="leadCreate.phone_number"
                      required
                      hideDetails
                      :disabled="pageLoading"
                      :loading="pageLoading"
                    >
                    </PhoneTextField>
                  </v-flex>
                </v-layout>
                <v-layout class="my-4">
                  <v-flex md4>
                    <label for="website" class="font-weight-700 font-size-16"
                      >Customer Website</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <v-text-field
                      id="website"
                      v-model.trim="leadCreate.website"
                      dense
                      filled
                      label="Website"
                      :rules="[
                        validateRules.minLength(
                          leadCreate.website,
                          'Website',
                          1
                        ),
                        validateRules.maxLength(
                          leadCreate.website,
                          'Website',
                          100
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout class="my-4">
                  <v-flex md4>
                    <label class="font-weight-700 font-size-16"
                      >Lead Date</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <DatePicker
                      solo
                      :pageLoading="pageLoading"
                      placeholder="Lead Date"
                      v-model="leadCreate.lead_date"
                    ></DatePicker>
                  </v-flex>
                </v-layout>
                <v-layout class="my-4">
                  <v-flex md4>
                    <label class="font-weight-700 font-size-16"
                      >Last Contacted Date</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <DatePicker
                      solo
                      clearable
                      :pageLoading="pageLoading"
                      placeholder="Last Contacted Date"
                      v-model="leadCreate.last_contact"
                    ></DatePicker>
                  </v-flex>
                </v-layout>
                <v-layout class="my-4">
                  <v-flex md4>
                    <label class="font-weight-700 font-size-16">Status</label>
                  </v-flex>
                  <v-flex md8>
                    <v-autocomplete
                      dense
                      color="cyan"
                      filled
                      :items="statusList"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="leadCreate.status"
                      label="Status"
                      solo
                      flat
                      item-color="cyan"
                      hide-details
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Status Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
                <v-layout class="my-4">
                  <v-flex md4>
                    <label class="font-weight-700 font-size-16">Source</label>
                  </v-flex>
                  <v-flex md8>
                    <v-autocomplete
                      dense
                      color="cyan"
                      filled
                      :items="sourceList"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="leadCreate.source"
                      label="Source"
                      solo
                      flat
                      item-color="cyan"
                      hide-details
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Source Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
              </v-col>
              <v-col md="6">
                <v-layout class="my-4">
                  <v-flex md4>
                    <label class="font-weight-700 font-size-16"
                      >Lead number</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <v-text-field
                      dense
                      color="cyan"
                      filled
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      readonly
                      v-model.trim="leadCreate.barcode"
                      label="Lead number"
                      solo
                      flat
                      hide-details
                      class="remove-border cursor-default font-weight-600"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout class="my-4">
                  <v-flex md4>
                    <label class="font-weight-700 font-size-16"
                      >Reference #</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <v-text-field
                      dense
                      color="cyan"
                      filled
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="leadCreate.reference"
                      label="Reference #"
                      :rules="[
                        validateRules.minLength(
                          leadCreate.reference,
                          'Reference',
                          1
                        ),
                        validateRules.maxLength(
                          leadCreate.reference,
                          'Reference',
                          100
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout class="my-4">
                  <v-flex md4>
                    <label class="font-weight-700 font-size-16">Unit No.</label>
                  </v-flex>
                  <v-flex md8>
                    <v-text-field
                      v-model.trim="leadCreate.unit_no"
                      dense
                      filled
                      label="Unit No."
                      solo
                      flat
                      :rules="[
                        validateRules.minLength(
                          leadCreate.unit_no,
                          'Unit No.',
                          1
                        ),
                        validateRules.maxLength(
                          leadCreate.unit_no,
                          'Unit No.',
                          100
                        ),
                      ]"
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout class="my-4">
                  <v-flex md4>
                    <label class="font-weight-700 font-size-16"
                      >Address Line 1</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <v-text-field
                      v-model.trim="leadCreate.address_1"
                      dense
                      filled
                      label="Address Line 1"
                      solo
                      flat
                      :rules="[
                        validateRules.minLength(
                          leadCreate.address_1,
                          'Address Line 1',
                          1
                        ),
                        validateRules.maxLength(
                          leadCreate.address_1,
                          'Address Line 1',
                          100
                        ),
                      ]"
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout class="my-4">
                  <v-flex md4>
                    <label class="font-weight-700 font-size-16"
                      >Address Line 2</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <v-text-field
                      v-model.trim="leadCreate.address_2"
                      dense
                      filled
                      label="Address Line 2"
                      solo
                      flat
                      :rules="[
                        validateRules.minLength(
                          leadCreate.address_2,
                          'Address Line 2',
                          1
                        ),
                        validateRules.maxLength(
                          leadCreate.address_2,
                          'Address Line 2',
                          100
                        ),
                      ]"
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout class="my-4">
                  <v-flex md4>
                    <label class="font-weight-700 font-size-16"
                      >Postal Code</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <v-text-field
                      v-model.trim="leadCreate.zip"
                      dense
                      filled
                      label="Postal Code"
                      v-mask="'######'"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout class="my-4">
                  <v-flex md4>
                    <label class="font-weight-700 font-size-16">Country</label>
                  </v-flex>
                  <v-flex md8>
                    <v-text-field
                      v-model.trim="leadCreate.country"
                      dense
                      filled
                      label="Country"
                      :rules="[
                        validateRules.minLength(
                          leadCreate.country,
                          'Country',
                          1
                        ),
                        validateRules.maxLength(
                          leadCreate.country,
                          'Country',
                          100
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout class="my-4">
                  <v-flex md4>
                    <label class="font-weight-700 font-size-16">Industry</label>
                  </v-flex>
                  <v-flex md8>
                    <v-autocomplete
                      dense
                      color="cyan"
                      filled
                      :items="industryList"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="leadCreate.industry"
                      label="Industry"
                      solo
                      flat
                      item-color="cyan"
                      hide-details
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Industry Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
                <v-layout class="my-4">
                  <v-flex md4>
                    <label class="font-weight-700 font-size-16"
                      >Lead Value</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <v-text-field
                      v-model.trim="leadCreate.lead_value"
                      dense
                      filled
                      label="Lead Value"
                      solo
                      flat
                      hide-details
                      type="number"
                      min="0"
                      prepend-inner-icon="mdi-currency-usd"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                      v-on:keypress="
                        limitDecimal($event, leadCreate.lead_value)
                      "
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout class="my-4">
                  <v-flex md4>
                    <label class="font-weight-700 font-size-16">Assigned</label>
                  </v-flex>
                  <v-flex md8>
                    <v-autocomplete
                      dense
                      color="cyan"
                      filled
                      :items="userList"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="leadCreate.assigned_user"
                      label="Assigned"
                      solo
                      flat
                      item-color="cyan"
                      item-text="full_name"
                      item-value="id"
                      hide-details
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No User Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                </v-layout>
              </v-col>
              <v-col md="12" class="py-0">
                <editor v-model="leadCreate.description" />
              </v-col>
              <v-col md="12">
                <FileTemplate
                  :attachments="attachments"
                  allowUpload
                  v-on:file:updated="updateAttachment"
                ></FileTemplate>
              </v-col>
            </v-row>
          </perfect-scrollbar>
        </v-container>
      </v-form>
    </template>
  </CreateUpdateTemplate>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CLEAR_ERROR, PUT, GET } from "@/core/services/store/request.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "lead-create",
  title: "Create Lead",
  data() {
    return {
      lead: 0,
      pageLoading: false,
      barcodeDialog: false,
      barcodeSetting: new Object(),
      statusList: new Array(),
      sourceList: new Array(),
      extraList: new Array(),
      industryList: new Array(),
      userList: new Array(),
      attachments: new Array(),
      leadCreate: new Object({
        title: null,
        name: null,
        company: null,
        email: null,
        phone_number: null,
        website: null,
        lead_date: null,
        last_contact: null,
        status: null,
        source: null,
        barcode: null,
        reference: null,
        unit_no: null,
        address_1: null,
        address_2: null,
        zip: null,
        country: null,
        industry: null,
        lead_value: null,
        assigned_user: null,
        description: null,
        attachments: [],
        city: null,
        state: null,
      }),
    };
  },
  watch: {
    "leadCreate.first_name"() {
      this.leadCreate.name =
        this.leadCreate.first_name + " " + this.leadCreate.last_name;
    },
    "leadCreate.last_name"() {
      this.leadCreate.name =
        this.leadCreate.first_name + " " + this.leadCreate.last_name;
    },
  },
  components: {
    DatePicker,
    PhoneTextField,
    FileTemplate,
    CreateUpdateTemplate,
    editor: TinyMCE,
  },
  methods: {
    updateAttachment(param) {
      this.leadCreate.attachments = param;
    },
    getOptions() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "leads/options",
        })
        .then(({ data }) => {
          _this.statusList = data.status_list;
          _this.sourceList = data.sources;
          _this.extraList = data.extras;
          _this.industryList = data.industries;
          _this.userList = data.users;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateOrCreate() {
      const _this = this;
      if (!_this.$refs.leadForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let formData = new Object({
        title: _this.leadCreate.title || null,
        name: _this.leadCreate.name || null,
        company: _this.leadCreate.company || null,
        email: _this.leadCreate.email || null,
        phone_number: _this.leadCreate.phone_number || null,
        website: _this.leadCreate.website || null,
        lead_date: _this.leadCreate.lead_date || null,
        last_contact: _this.leadCreate.last_contact || null,
        status: _this.leadCreate.status || null,
        source: _this.leadCreate.source || null,
        barcode: _this.leadCreate.barcode || null,
        reference: _this.leadCreate.reference || null,
        unit_no: _this.leadCreate.unit_no || null,
        address_1: _this.leadCreate.address_1 || null,
        address_2: _this.leadCreate.address_2 || null,
        zip: _this.leadCreate.zip || null,
        country: _this.leadCreate.country || null,
        industry: _this.leadCreate.industry || null,
        lead_value: _this.leadCreate.lead_value || null,
        assigned_user: _this.leadCreate.assigned_user || null,
        description: _this.leadCreate.description || null,
        attachments: _this.leadCreate.attachments || [],
        city: _this.leadCreate.city || null,
        state: _this.leadCreate.state || null,
      });

      _this.$store
        .dispatch(PUT, {
          url: "leads/" + _this.lead,
          data: formData,
        })
        .then(({ data }) => {
          _this.$router.push(
            _this.getDefaultRoute("lead.detail", {
              params: {
                id: data.id,
              },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    getLead() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "leads/" + _this.lead,
        })
        .then(({ data }) => {
          _this.leadCreate = new Object({
            barcode: data.barcode || null,
            reference: data.reference || null,
            assigned_user: data.assigned_user ? data.assigned_user.id : null,
            name: data.name || null,
            company: data.company || null,
            email: data.email || null,
            website: data.website || null,
            phone_number: data.phone_number || null,
            country: data.country || null,
            zip: data.zip || null,
            city: data.city || null,
            state: data.state || null,
            unit_no: data.unit_no || null,
            address_1: data.address_1 || null,
            address_2: data.address_2 || null,
            title: data.title || null,
            description: data.description || null,
            status: data.status || null,
            source: data.source ? data.source.id : null,
            extra: data.extra ? data.extra.id : null,
            industry: data.industry ? data.industry.id : null,
            lead_value: data.lead_value || null,
            lead_date: data.lead_date || null,
            last_contact: data.last_contact || null,
            attachments: [],
          });
          _this.attachments = data.attachments;
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.getOptions();
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Lead", route: "lead" },
      { title: "Update" },
    ]);

    _this.lead = _this.lodash.toSafeInteger(_this.$route.params.id);

    if (_this.lead <= 0) {
      _this.goBack();
    }

    _this.getLead();
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    _this.routePreventDialog = true;
    PreventDialogEventBus.$on("stay-on-page", function () {
      _this.routePreventDialog = false;
      next(false);
    });
    PreventDialogEventBus.$on("leave-page", function () {
      _this.routePreventDialog = false;
      next();
    });
  },
};
</script>
